import React, { useState, useRef, useEffect } from "react"
import Video from "../components/Video";
import { VideoType, WebViewVideoType } from "../types/models/Media/Video";
import { VideoSelectActionType, WebViewVideoAction, WebViewVideoActionType } from "../types/WebViewVideoAction";


const VideoForWebViewPage = ( {
    location
}: any ) => {

    const [ file, setFile ] = useState( location.search.split( '?src=' )[ 1 ] )
    const [ fileMp4, setFileMp4 ] = useState( "" )

    const videoContainer = useRef<HTMLDivElement>( null )

    useEffect( () => {
        const file__ = location.search.split( '?src=' )[ 1 ]
        setFile( location.search.split( '?src=' )[ 1 ] )
        const fileMp4 = file__.split( '/' ).slice( 0, -1 ).join( '/' ) + '.mp4'
        setFileMp4( fileMp4 )
    }, [ location ] )

    const handleVideoSelectSwitch = ( type: VideoSelectActionType, video: VideoType | WebViewVideoType ) =>
        webViewDispatch( {
            select: WebViewVideoAction.VIDEO_SELECT,
            release: WebViewVideoAction.VIDEO_RELEASE,
            save: WebViewVideoAction.SAVE_VIDEO,
        }[ type ] )

    useEffect( () => {
        setFile( location.search.split( '?src=' )[ 1 ] )
    }, [] )

    const webViewDispatch = ( type: WebViewVideoActionType ) => {
        let data
        switch ( type ) {
            case WebViewVideoAction.VIDEO_LOADED:
                data = {
                    event: WebViewVideoAction.SET_VIDEO_CONTROLLER,
                    height: String( videoContainer.current ?
                        videoContainer.current.getBoundingClientRect().height :
                        300 )
                }
                break
            case WebViewVideoAction.START_SEEK:
                data = { event: WebViewVideoAction.START_SEEK }
                break
            case WebViewVideoAction.END_SEEK:
                data = { event: WebViewVideoAction.END_SEEK }
                break
            case WebViewVideoAction.START_DRAW:
                const canvasBackGround = document.getElementById( "canvas_background" )
                data = {
                    event: WebViewVideoAction.START_DRAW,
                    height: String( canvasBackGround ?
                        canvasBackGround.getBoundingClientRect().height
                        : 300 )
                }
                break
            case WebViewVideoAction.END_DRAW:
                data = { event: WebViewVideoAction.END_DRAW }
                break
            case WebViewVideoAction.VIDEO_SELECT:
                data = { event: WebViewVideoAction.VIDEO_SELECT, src: file }
                break
            case WebViewVideoAction.VIDEO_RELEASE:
                data = { event: WebViewVideoAction.VIDEO_RELEASE, src: file }
                break
            case WebViewVideoAction.SAVE_VIDEO:
                data = { event: WebViewVideoAction.SAVE_VIDEO }
                break
            case WebViewVideoAction.ADD_IMAGE_TO_COMMENT:
                data = {
                    event: WebViewVideoAction.ADD_IMAGE_TO_COMMENT,
                    image: window.commentFormFile,
                    srcURL: window.canvasImageURL
                }
                break
            case WebViewVideoAction.DOWNLOAD_CANVAS_IMAGE:
                data = {
                    event: WebViewVideoAction.DOWNLOAD_CANVAS_IMAGE,
                    image: window.canvasImageFile,
                    srcURL: window.canvasImageURL
                }
                break
            case WebViewVideoAction.DOWNLOAD_VIDEO:
                data = { event: WebViewVideoAction.DOWNLOAD_VIDEO }
                break
        }
        try {
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage( JSON.stringify( data ) );
        }
        catch ( error ) {
            alert( error )
        }
    }

    return (
        <div ref={ videoContainer }>
            <Video
                sync={ false }
                index={ 0 }
                forComparison={ false }
                action={ null }
                dispatch={ ( action ) => { } }
                originalFile={ file }
                thumbnail={ undefined }
                handleVideoSelectSwitch={ handleVideoSelectSwitch }
                videoIsSelected={ false }
                showThumbnailInitially={ false }
                videoLoadAndAutoPlay={ true }
                forWebView={ true }
                webViewDispatch={ webViewDispatch }>
            </Video>
        </div>
    )
}

export default VideoForWebViewPage
